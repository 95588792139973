import { gql } from 'urql/core';

export default gql`
query getMedicationTypes {
  medicationTypes {
    id
    name
  }
}
`;
