import { gql } from 'urql/core';

export default gql`
  mutation createMedicationType($input: CreateMedicationTypeInput!) {
    createMedicationType(input: $input) {
      id
      name
    }
  }
`;
