import { render } from "./MedicationsCreate.vue?vue&type=template&id=725c54a0&scoped=true"
import script from "./MedicationsCreate.vue?vue&type=script&lang=js"
export * from "./MedicationsCreate.vue?vue&type=script&lang=js"

import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css?vue&type=style&index=0&lang=css"
import "./MedicationsCreate.vue?vue&type=style&index=1&id=725c54a0&lang=scss"
import "./MedicationsCreate.vue?vue&type=style&index=2&id=725c54a0&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-725c54a0"

export default script