<script>
import { reactive, computed } from 'vue';
import { useQuery, useMutation } from '@urql/vue';
import Button from '@/components/Button.vue';
import FormInput from '@/components/form/FormInput.vue';

import GetMedicationTypes from '@/queries/GetMedicationTypes';
import CreateMedicationType from '@/queries/CreateMedicationType';

export default {
  components: {
    Button,
    FormInput,
  },
  emits: {
    close: Object,
    created: Object,
  },
  setup(props, context) {
    const model = reactive({
      name: '',
      globalError: '',
    });

    const errors = reactive({
      name: false,
    });

    const medicationTypesResult = useQuery({
      query: GetMedicationTypes,
    });

    const medTypes = computed(() => {
      if (medicationTypesResult.data.value && medicationTypesResult.data.value.medicationTypes) {
        return medicationTypesResult.data.value.medicationTypes;
      }
      return [];
    });

    const { executeMutation: executeCreateMedType } = useMutation(CreateMedicationType);

    const resetErrors = () => {
      Object.keys(errors).forEach((key) => {
        errors[key] = false;
      });
    };

    const checkErrors = () => {
      resetErrors();
      let hasErrors = false;
      if (model.name.length <= 0) {
        errors.name = true;
        hasErrors = true;
      }
      return hasErrors;
    };

    const createMedType = async () => {
      const hasErrors = checkErrors();

      if (hasErrors) {
        return;
      }

      // refresh the list of types
      await medicationTypesResult.executeQuery({
        requestPolicy: 'network-only',
      });

      const exists = medTypes.value.some(item => item.name === model.name);

      if (exists) {
        model.globalError = 'A medication type with that name already exists.';
        return;
      }

      const input = {
        name: model.name,
      };

      executeCreateMedType({ input })
        .then((result) => {
          if (result.errors) {
            model.globalError = 'Something happened.';
            // do something about it;
          }
          context.emit('created', result.data.createMedicationType);
          context.emit('close');
        });
    };

    return {
      model,
      errors,
      medTypes,
      createMedType,
    };
  },
};
</script>
<template>
  <div class="overlay">
    <div class="modal">
      <div class="top">
        <span class="title">
          New Medication Type
        </span>
        <span @click="$emit('close')">
          <svg
            class="close-icon"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </span>
      </div>
      <div class="body">
        <div
          class="error-banner"
          v-if="model.globalError.length > 0"
        >
          {{model.globalError}}
        </div>
        <FormInput
          label="Medication Type Name"
          v-model="model.name"
          :error="errors.name"
        />
      </div>
      <div class="footer">
        <Button
          @click="createMedType"
        >
          Create
        </Button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .error-banner {
    background: rgba($ERROR_COLOR, .2);
    border-left: 5px solid $ERROR_COLOR;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    color: $ERROR_COLOR;
  }
  .close-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .overlay {
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal {
    background: white;
    width: 30rem;
    border-radius: .5rem;

    .top {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 1rem;

      .title {
        flex: 1;
        font-size: 1.25rem;
        font-weight: bold;
      }
    }
    .body {
      padding: .5rem 1rem;
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 1rem;
    }
  }
</style>
